import { Link } from "gatsby";
import React, { useEffect } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { TextLoop } from "react-text-loop-next";
import "../../styles/components/FullScreenMenu.scss";
import useWindowSize from "./../_hooks/useWindowSize";
import Menu3D from "./../_pages/home/3DBackground/Menu3D";
export default function FullScreenMenu() {
  // const [size, setsize] = useState(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth);

  // useEffect(() => {
  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // });

  // const handleResize = () => {
  //   setsize(window.innerWidth);
  // };

  useEffect(() => {
    let elements = document.querySelectorAll(".tonyes-menu .nav-link, .tonye-credits a");
    elements.forEach(element => {
      let innerText = element.innerText;
      element.innerHTML = "";
      let textContainer = document.createElement("div");
      textContainer.classList.add("block");
      for (let letter of innerText) {
        let span = document.createElement("span");
        span.innerText = letter.trim() === "" ? "\xa0" : letter;
        span.classList.add("letter");
        textContainer.appendChild(span);
      }
      element.appendChild(textContainer);
      element.appendChild(textContainer.cloneNode(true));
    });

    // for presentation purpose
    setTimeout(() => {
      elements.forEach(element => {
        element.classList.add("play");
      });
    }, 600);
    elements.forEach(element => {
      element.addEventListener("mouseover", () => {
        element.classList.remove("play");
      });
    });
  }, []);
  const size = useWindowSize();
  return <div className="tonyes-menu">
      {/* <video src={tonyeMP4} muted autoPlay loop /> */}
      <li>
        <Link to="/about" className="nav-link " activeClassName="active-link">
          <span className="nav-link-span">About</span>
        </Link>
        <Link to="/portfolio" className="nav-link " activeClassName="active-link">
          <span className="nav-link-span">Portfolio</span>
        </Link>
        {/* <Link
          to="/stories"
          className="nav-link "
          activeClassName="active-link"
         >
          <span className="nav-link-span">Stories</span>
         </Link> */}
        <Link to="/contact" className="nav-link " activeClassName="active-link">
          <span className="nav-link-span">Contact</span>
        </Link>

        <Link to="/blog" className="nav-link " activeClassName="active-link">
          <span className="nav-link-span">Blog</span>
        </Link>
      </li>

      <div className="tonye-credits">
        <Row className="line">
          <Col className="line-inner">
            <TextLoop interval={2800} className="text-loop">
              <span>Tonye Brown</span>
              <span>My wife calls Me "Bez"</span>
              <span>Front End Developer</span>
              <span>Designer</span>
              <span>Christ Follower</span>
              <span>Rain lover</span>
              <span>Writer</span>
              <span>Musician</span>
            </TextLoop>
          </Col>
          {size.width > 768 && <Col className="line-inner">
              <Link to="/about">About</Link>
              <a href="https://www.twitter.com/truevined">Twitter</a>
              <a href="https://www.linkedin.com/in/tonyeb/">LinkedIn</a>
            </Col>}
          <Col className="copyright line-inner">
            <span>
              &copy; {new Date().getUTCFullYear()} {size.width > 768 && "Tonye Brown"}
            </span>
          </Col>
        </Row>
      </div>
      <div className="canvas-wrapper" style={{
      width: size.width,
      height: size.height
    }}>
        <Menu3D />
      </div>
    </div>;
}